<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
      <template>
        <b-form @submit.prevent="handleSubmit">
          <b-tabs>
            <b-tab title="Category">
              <b-card no-body>
                <b-card-header>
                  <b-row>
                    <b-col lg="8">
                      <h5 class="card-title">Add Category</h5>
                    </b-col>
                  </b-row>
                </b-card-header>
                <b-card-body>
                  <b-row>
                    <b-col lg="8">
                      <b-row>
                        <b-col lg="7">
                          <b-form-group>
                            <label>Category Name<span class="text-danger mr5">*</span></label>
                            <b-form-input id="input-formatter" v-model="text1" placeholder="e.g Reksadana"
                              :formatter="formatter">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col lg="5">
                          <b-form-group>
                            <label>Parent Category<span class="text-danger mr5">*</span></label>
                            <v-select :options="['Root ','Investasi','Mengelola Keuangan ','Hutang','Tips Usaha']" />
                          </b-form-group>
                        </b-col>
                        <b-col lg="6">
                          <b-form-group>
                            <label>Slug<span class="text-danger mr5">*</span></label>
                            <b-form-input id="input-formatter" v-model="text1" placeholder="e.g Karu Kredit"
                              :formatter="formatter">
                            </b-form-input>
                          </b-form-group>
                        </b-col>
                        <b-col lg="12">
                          <b-form-group label-for="articleTitle">
                            <template #label>Description</template>
                            <b-form-textarea id="textarea" v-model="text" placeholder="Enter something..." rows="4"
                              max-rows="4">
                            </b-form-textarea>
                          </b-form-group>
                        </b-col>
                        <b-col lg="6">
                          <b-form-group class="mt-3">
                            <label>Status<span class="text-danger mr5">*</span></label>
                            <b-form-radio-group v-model="selected_status" :options="status" class="mb-3"
                              value-field="item" text-field="name" disabled-field="notEnabled">
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col lg="4">
                      <div class="side_wrap">
                        <b-row>
                          <b-col lg="12" class="mb-2">
                            <bo-uploader :img-src="articleRow.thumbnail">
                              <template #label>Image <span class="text-danger ml5">*</span></template>
                            </bo-uploader>
                          </b-col>
                          <b-col lg="12" class="mb-2">
                            <b-form-group>
                              <label>Alt Image<span class="text-danger mr5">*</span></label>
                              <b-form-input id="input-formatter" v-model="text1" placeholder="e.g Image title"
                                :formatter="formatter">
                              </b-form-input>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </b-card-body>
                <b-card-footer>
                  <b-row>
                    <b-col lg="12" class="text-right">
                      <button type="submit" class="btn  btn-rounded  btn-default mr-3">Cancel</button>
                      <button type="submit" class="btn  btn-rounded  btn-success">Submit</button>
                    </b-col>
                  </b-row>
                </b-card-footer>
              </b-card>
            </b-tab>
            <b-tab title="SEO Setting">
              <b-card>
                <b-row>
                  <b-col lg="12">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Meta Title
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoRow.title" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="9">
                    <b-form-group label-for="homeMetaDesc">
                      <template #label>
                        Meta Description
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-textarea rows="4" id="homeMetaDesc" placeholder="Meta Description"
                        v-model="seoRow.description" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="9">
                    <b-form-group label-for="homeMetaTags">
                      <template #label>
                        Meta Tags
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete
                        tag-variant="success" tag-class="text-white" v-model="seoRow.keywords" />
                    </b-form-group>
                  </b-col>
                  <b-col lg="7">
                    <b-form-group label-for="homeMetaTitle">
                      <template #label>
                        Canonical URL
                        <b-badge variant="info" pill class="help-badge"
                          v-b-tooltip.hover.right="'A canonical URL is the URL of the page that Google thinks is most representative from a set of duplicate pages on your site.'">
                          <i class="fas fa-question"></i>
                        </b-badge>
                      </template>
                      <b-form-input id="homeMetaTitle" placeholder="e.g https://duwitmu.com/" v-model="seoRow.title" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-form>
      </template>
    </b-container>
  </div>
</template>
<script>
  import BoUploader from '@/components/BoUploader.vue'
  import GlobalVue from '@/libs/Global.vue'

  export default {
    name: 'Article',
    extends: GlobalVue,
    components: {
      BoUploader,
    },

    mounted() {
      this.loadSeoData()
      this.loadArticleData()
    },

    computed: {
      dataSrc() {
        return this.dummyData.article
      },
    },

    methods: {
      loadSeoData() {
        let datas = this.dataSrc.seo
        this.seoRow = datas
      },
      loadArticleData() {
        let datas = this.dataSrc.articleList
        datas.forEach(el => {
          this.articleData.push(el)
        })
      }
    },
    data() {
      return {
        selected: 'A',
        ads_device: [{
            item: 'A',
            name: 'Desktop'
          },
          {
            item: 'B',
            name: 'Mobile'
          }
        ],
        date: new Date(),
        days: [],
        featured: 'not_accepted',
        seoRow: {},
        articleRow: {},
        articleData: [],
        rows: 30,
        perPage: 1,
        currentPage: 5,
        selected_category: 'All Category',
        category: [
          'All Category',
          'Pinjaman Online',
          'Asuransi',
          'Bank Digital',
          'Investasi',
          'Rencana Keuangan',
        ],
        selected_sort: 'Sort By',
        sort: [
          'Sort By',
          'Most Viewed',
          'Least Viewed',
          'Oldest',
          'Newest',
        ],
        selected_ads_size: 'Select Ads Size',
        ads_size: [
          'Select Ads Size',
          '300x250',
          '336x280',
          '728x90',
          '300x600',
          '320x100',
          '200x50',
          '320x20',
        ],
        selected_parent: ' ---Select Parent Catgeory ---',
        parent: [
          ' ---Select Parent Catgeory ---',
          'Asuransi',
          'Advetorial',
          'Pengeluaran',
          'Saham'
        ],
        selected_status: 'Y',
        status: [{
            item: 'Y',
            name: 'Active'
          },
          {
            item: 'N',
            name: 'Inactive'
          }
        ],
      }
    },
    watch: {
      $route() {
        if (!this.isList) {
          if (!this.isAdd) {
            this.articleRow = this.articleData.find(el => el.id == this.$route.params.slug)
            console.log(this.articleRow)
          } else {
            this.articleRow = {}
          }
        }
      }
    }
  }
</script>